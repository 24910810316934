<template>
  <NuxtLink
    :to="link"
    :class="[
      'blog-author',
      {
        'blog-author--link': !disableLink,
      },
    ]"
    :disabled="disableLink"
  >
    <div
      :class="[
        'blog-author__content',
        {
          'blog-author--large': isLarge,
        },
      ]"
    >
      <BaseNuxtImage
        :src="src"
        :alt="alt"
        loading="lazy"
        class="blog-author__picture"
      />
      <div class="blog-author__info">
        <div class="blog-author__name">
          {{ name }}
        </div>
        <div v-if="showPosition" class="blog-author__role paragraph--xs">
          {{ role }}
        </div>
        <div
          :class="[
            'blog-author__description paragraph--s',
            { 'blog-author__description--hidden': isDescriptionHidden },
          ]"
        >
          {{ description }}
        </div>
      </div>
    </div>
  </NuxtLink>
</template>

<script lang="ts" setup>
import type { BlogAuthorStoryblok } from '@/types'
import type { ISbStoryData } from '@storyblok/js'

interface Props {
  author: ISbStoryData<BlogAuthorStoryblok>
  isLarge?: boolean
  disableLink?: boolean
  isDescriptionHidden?: boolean
}
const props = defineProps<Props>()

const name = computed(() => {
  return props.author.content?.name || ''
})
const role = computed(() => {
  return props.author.content?.role || ''
})
const description = computed(() => {
  return props.author.content?.description || ''
})
const link = computed(() => {
  return `/blog/authors/${props.author.slug}`
})
const src = computed(() => {
  return props.author.content?.photo?.filename || ''
})
const alt = computed(() => {
  return props.author.content?.photo?.alt || ''
})
const showPosition = computed(() => {
  return role.value.length > 0
})
</script>

<style lang="scss" scoped>
.blog-author {
  $root-el: #{&};

  text-decoration: none;
  cursor: default;

  &__content {
    display: flex;
    align-items: flex-start;
    color: var(--cobalt-800);
  }

  &--link {
    cursor: pointer;

    &:hover {
      text-decoration: none;

      #{$root-el}__name {
        color: var(--color-link);
      }
    }
  }

  &--large {
    flex-direction: column;

    @media (min-width: $breakpoint-md) {
      flex-direction: row;
    }

    #{$root-el}__picture {
      width: 100%;
      height: 100%;
      max-width: 150px;
      max-height: 150px;
      margin-bottom: 20px;

      @media (min-width: $breakpoint-md) {
        width: 150px;
        height: 150px;
        margin-bottom: 0;
      }
    }

    .blog-author__name {
      @include heading-3;
    }
  }

  &__picture {
    width: 112px;
    height: 112px;
    object-fit: cover;
    border-radius: 999px;
    margin-right: 40px;

    @media (min-width: $breakpoint-md) {
      width: 112px;
      height: 112px;
      margin-right: 40px;
    }
  }

  &__name {
    @include heading-5;

    font-weight: 600;
  }

  &__role {
    font-size: var(--font-size-paragraph-s);
    color: var(--navy-600);
    margin-bottom: 8px;
  }

  &__description--hidden {
    display: none;

    @media (min-width: $breakpoint-md) {
      display: block;
    }
  }
}
</style>
